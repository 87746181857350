.sidebar {
  width: 100%;
  max-width: 322px;
}

.radioBlock {
  width: 100%;
  margin-bottom: 24px;
}

.radioTitle {
  margin-bottom: 8px;
}

.radioBoxes {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
}

.searchSection {
  width: 100%;
  max-width: 322px;
  display: flex;
  flex-direction: column;
  grid-column: 1;
  grid-row: 1;
}

.servicesBlock {
  width: 100%;
  max-width: 322px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  grid-column: 1;
  grid-row: 2;
}

.servicesBlock > div:first-child {
  border-top: none;
  padding-top: 0;
}

.sidebar {
  width: 100%;
  max-width: 322px;
}

@media screen and (max-width: 960px) {
  .servicesBlock {
    max-width: 960px;
    grid-row: 3;
  }

  .searchSection {
    max-width: unset;
  }
}
