:root {
  --font-family: 'Opel Next', sans-serif;

  --pin-selected: #ffba00;
  --yellow: #F7FF14;
  --pin: #000;
  --white: #fff;
  --black: #000;
  --gray: #606060;
  --light-gray: #d4d3d3;
  --dark-gray: #a19f9f;
  --bg-gray: #f3f3f3;
  --red: #EE432F;
}
